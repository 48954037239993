import React, { useState } from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { Link, FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Slider from "react-slick"

import Layout from "../components/layout"
import Head from "../components/head"
import JobAccordion from "../components/jobAccordion"

import useContentfulJobposts from "../hooks/use-contentful-jobposts"
import { SmoothVerticalScrolling } from "../utils/scroll"

import paperPlane from "../images/icons/paper-plane.svg"
import arrow from "../images/icons/arrow-down.svg"

const FAQSection = styled.div`
  ${tw`bg-light pt-12 md:pt-32 pb-14`}
`

const Container = styled.div`
  ${tw`px-5 relative max-w-default w-full flex`};
  margin: 0 auto;
`
const FAQContainer = styled.div`
  ${tw`px-5 relative max-w-default w-full justify-between flex flex-col md:flex-row`};
  margin: 0 auto;
`

const IntroductionContent = styled.div`
  ${tw` px-5 relative lg:items-center pt-4 sm:pt-6 md:pt-12 lg:pt-20 pb-7 sm:pb-10 md:pb-16 lg:pb-24  max-w-default  flex flex-col lg:flex-row w-full`};
  margin: 10px auto 14px;
`
const Title = styled.h1`
  ${tw`text-40 sm:text-5xl md:text-xxl leading-snug w-full md:w-2/5 font-semibold text-dark`}
  max-width: 430px;
`

const DescriptionWrapper = styled.div`
  ${tw`w-full lg:w-3/5 pt-8 lg:pt-0`}
`

const Description = styled.p`
  ${tw`text-base md:text-lg text-dark font-light lg:pl-12 leading-testimonial`}
`

const ImageDivider = styled.img`
  ${tw`w-full`}
  max-height:360px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  @media only screen and (max-width: 1440px) {
    max-height: 300px;
  }
`

const FilterWrapper = styled.div`
  ${tw`hidden md:block w-full mt-5 lg:w-2/5`}
  max-width: 264px;
`

const FilterWrapperMobile = styled(Slider)`
  ${tw`flex outline-none focus:outline-none md:hidden items-center pb-6 mb-1`}
`

const FilterLabel = styled.div`
  ${tw`pb-8 flex pt-7 cursor-pointer`}
  border-bottom: 2px solid #ABAFB5;
`

const FilterLabelMobile = styled.div`
  ${tw`flex items-center whitespace-no-wrap mr-5 outline-none focus:outline-none cursor-pointer`}
  display:flex !important;
`

const FilterImgMobile = styled.img`
  ${tw`mr-3`}
`
const FilterTextMobile = styled.p`
  ${tw`mr-2`}
`

const FilterImg = styled.img`
  ${tw``}
`

const FilterText = styled.p`
  ${tw`text-22 pl-5 text-dark leading-32`}
  margin-top: 3px;
`

const AccordionWrapper = styled.div`
  ${tw` ml-0 md:ml-16 w-full`}
  max-width:750px
`

const FooterSection = styled.div`
  ${tw`bg-light pt-0 md:pt-14  pb-26 md:pb-32`}
`

const FooterWrapper = styled.div`
  ${tw`flex`}
`

const FooterContent = styled.div`
  ${tw`pr-0 md:pr-32`}
`

const FooterText = styled.p`
  ${tw`text-dark text-base md:text-lg font-normal md:font-semibold leading-32 pt-2 md:pt-8 pb-10 md:pb-16`}
  max-width: 600px;
`

const CTA = styled(Link)`
  ${tw`text-dark text-lg font-semibold bg-primary px-12 py-3 rounded-full focus:outline-none hover:bg-primaryHovered duration-200 whitespace-no-wrap`}
  display: inline-block;
`

const FooterIllustration = styled.img`
  ${tw`ml-5 hidden md:block`}
`

const ScrollToTop = styled.div`
  ${tw`absolute cursor-pointer flex md:hidden items-center bottom-0 right-0 mr-5 opacity-40 mb-8`}
`

const ScrollToTopText = styled.p`
  ${tw`text-dark font-semibold pr-3`}
`

const IconArrow = styled.img`
  ${tw``}
  transform: rotate(180deg)
`

const OffersPage = () => {
  const intl = useIntl()
  const JobPosts = useContentfulJobposts(intl.locale)
  const [department, setDepartment] = useState(JobPosts[0].department)
  const [headerImage, setHeaderImage] = useState("job-1")

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    focusOnSelect: true,
    arrows: false,
    slidesToShow: 1,
    variableWidth: true,
  }

  function groupBy(objectArray, property) {
    return objectArray.reduce(function(acc, obj) {
      let key = obj[property]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})
  }

  let jobs = { ...groupBy(JobPosts, "department") }

  function handleClick(dep, sort) {
    setHeaderImage(sort)
    setDepartment(dep)
  }

  const scrollTop = () => {
    SmoothVerticalScrolling(document.getElementById("jobs-section"), 400, "top")
  }

  return (
    <Layout>
      <Head
        lang={intl.locale}
        title={intl.formatMessage({ id: "navigation.offers" })}
      />
      <IntroductionContent>
        <Title>
          <FormattedMessage id="offers.title" />
        </Title>
        <DescriptionWrapper>
          <Description>
            <FormattedMessage id="offers.description_part_1" />
            <b>
              <FormattedMessage id="offers.description_part_bold" />
            </b>
            <FormattedMessage id="offers.description_part_2" />
          </Description>
        </DescriptionWrapper>
      </IntroductionContent>
      <ImageDivider src={`/hero-${headerImage}.png`} />
      <FAQSection id="jobs-section">
        <FAQContainer>
          <FilterWrapper>
            {Object.entries(jobs).map((job, i) => {
              return (
                <FilterLabel
                  key={i}
                  onClick={() => handleClick(job[0], job[1][0].sort)}
                >
                  <FilterImg
                    style={{
                      opacity: department === job[0] ? "1" : ".4",
                    }}
                    src={`/icons/desktop/${job[1][0].sort}.svg`}
                  ></FilterImg>
                  <FilterText
                    style={{
                      opacity: department === job[0] ? "1" : ".4",
                      fontWeight: department === job[0] ? "600" : "300",
                    }}
                  >
                    {job[0]}
                  </FilterText>
                </FilterLabel>
              )
            })}
          </FilterWrapper>
          <FilterWrapperMobile {...settings}>
            {Object.entries(jobs).map((job, i) => {
              return (
                <FilterLabelMobile
                  key={i}
                  onClick={() => handleClick(job[0], job[1][0].sort)}
                >
                  <FilterImgMobile
                    style={{
                      opacity: department === job[0] ? "1" : ".4",
                    }}
                    src={`/icons/mobile/${job[1][0].sort}.svg`}
                  ></FilterImgMobile>
                  <FilterTextMobile
                    style={{
                      opacity: department === job[0] ? "1" : ".4",
                      fontWeight: department === job[0] ? "600" : "300",
                    }}
                  >
                    {job[0]}
                  </FilterTextMobile>
                </FilterLabelMobile>
              )
            })}
          </FilterWrapperMobile>
          {Object.entries(jobs).map((job, i) => {
            return (
              <React.Fragment key={i}>
                {job[0] === department ? (
                  <AccordionWrapper>
                    {jobs[department].map((job, i) => {
                      return (
                        <JobAccordion
                          key={i}
                          position={job.position}
                          wage={job.wage}
                          location={job.location}
                          contract={job.contract}
                          period={job.period}
                          icon={job.icon}
                          sort={job.sort}
                          details={job.details}
                        ></JobAccordion>
                      )
                    })}
                  </AccordionWrapper>
                ) : (
                  <></>
                )}
              </React.Fragment>
            )
          })}
        </FAQContainer>
      </FAQSection>
      <FooterSection>
        <Container>
          <FooterWrapper>
            <FooterContent>
              <FooterText>
                <FormattedMessage id="offers.footer_text" />
              </FooterText>
              <CTA to="/contact">
                <FormattedMessage id="offers.footer_cta" />
              </CTA>
            </FooterContent>
            <FooterIllustration src={paperPlane} />
          </FooterWrapper>
        </Container>
      </FooterSection>
      <Container>
        <ScrollToTop onClick={scrollTop}>
          <ScrollToTopText>
            <FormattedMessage id="faq.scroll_top" />
          </ScrollToTopText>
          <IconArrow src={arrow} alt="arrow" />
        </ScrollToTop>
      </Container>
    </Layout>
  )
}

export default OffersPage
