import { graphql, useStaticQuery } from "gatsby"

const useContentfulJobPosts = locale => {
  const data = useStaticQuery(graphql`
    query {
      sk: allContentfulJobPost(sort: { fields: createdAt, order: ASC }) {
        edges {
          node {
            position: positionSk
            department: departmentSk
            wage: wageSk
            location: locationSk
            contract: contractSk
            period: workPeriodSk
            details: detailsSk {
              content: detailsSk
            }
            sort: sort
            icon: icon
          }
        }
      }
      en: allContentfulJobPost(sort: { fields: createdAt, order: ASC }) {
        edges {
          node {
            position: positionEn
            department: departmentEn
            wage: wageSk
            location: locationEn
            contract: contractEn
            period: workPeriodEn
            details: detailsEn {
              content: detailsEn
            }
            icon: icon
            sort: sort
          }
        }
      }
    }
  `)
  return data[locale].edges.map(job => ({
    position: job.node.position,
    department: job.node.department,
    wage: job.node.wage,
    location: job.node.location,
    contract: job.node.contract,
    period: job.node.period,
    details: job.node.details.content,
    icon: job.node.icon,
    sort: job.node.sort
  }))
}

export default useContentfulJobPosts
