import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { Link, FormattedMessage } from "gatsby-plugin-intl"

import arrow from "../images/icons/arrow-down.svg"
import moneyIcon from "../images/icons/money-icon.svg"
import locationIcon from "../images/icons/location-icon.svg"
import moneyIconMobile from "../images/icons/money-icon-mobile.svg"
import locationIconMobile from "../images/icons/location-icon-mobile.svg"

const AccordionWrapper = styled.div`
  ${tw`rounded-30 md:rounded-40 shadow-values p-6 md:p-7 lg:p-8 bg-white mt-5 mb-8 md:mb-12 cursor-pointer`}
`

const AccordionTitleSection = styled.div`
  ${tw`flex justify-between items-center`}
`

const AccrodionTitle = styled.h1`
  ${tw`text-base md:text-22 pr-6 font-semibold text-dark leading-32`}
`
const Icon = styled.img`
  ${tw`duration-500`}
  transition-delay: 50ms;
`
const IconMeta = styled.img`
  ${tw`hidden md:flex mr-2`}
`
const IconMetaMobile = styled.img`
  ${tw`flex md:hidden`}
  margin-right:7px
`

const AccordionHead = styled.div`
  ${tw`flex`}
`
const AccordionIllustrationWrapper = styled.div`
  ${tw`rounded-full mr-5 lg:mr-7 flex items-center justify-center`}
  height: 80px;
  width: 80px;
  @media only screen and (max-width: 767px) {
    height: 60px;
    width: 60px;
  }
`
const AccordionIllustration = styled.img`
  ${tw`hidden md:flex`}
`

const AccordionIllustrationMobile = styled.img`
  ${tw`flex md:hidden`}
`

const AccordionMetaWrapper = styled.div`
  ${tw`pt-1`}
`
const AccordionMetaDetails = styled.div`
  ${tw`flex mt-0 md:mt-2`}
`

const AccordionMetaWage = styled.div`
  ${tw`flex text-xs md:text-sm lg:text-base mr-10px md:mr-4 lg:mr-7`}
`

const AccordionMetaLocation = styled.div`
  ${tw`flex text-xs md:text-sm lg:text-base`}
`

const AccordionDescriptionSection = styled.div`
  ${tw`px-1 md:px-4`}
  overflow: hidden;
  transition: max-height 0.5s ease-in-out !important;
  height: auto;
`

const AccordionDescription = styled.div`
  ${tw`pt-8`}
`

const AccordionDescriptionMeta = styled.p`
  ${tw`font-light text-dark text-base md:text-18 leading-32`}
`
const SemiBold = styled.span`
  ${tw`font-semibold`}
`

const AccordionDetails = styled.p`
  ${tw`pt-6 font-light text-dark text-base md:text-18 leading-32 mb-10`}
`

const AccordionCTA = styled(Link)`
  ${tw`text-dark text-lg font-semibold bg-primary text-center md:text-left w-full md:w-auto px-5 md:px-12 py-3 rounded-full focus:outline-none hover:bg-primaryHovered duration-200 whitespace-no-wrap`}
  display: inline-block;
`

const Accordion = ({
  position,
  wage,
  location,
  contract,
  period,
  icon,
  details,
  sort,
}) => {
  const [open, setOpen] = useState(true)
  const [open2, setOpen2] = useState()
  const [height, setHeight] = useState(null)
  const elRef = useRef()

  useEffect(() => {
    setHeight(`${elRef.current.clientHeight}px`)
    setOpen(false)
  }, [])

  function handleChange() {
    setOpen(!open)
    setOpen2(!open)
  }

  return (
    <AccordionWrapper onClick={() => handleChange()}>
      <AccordionTitleSection>
        <AccordionHead>
          <AccordionIllustrationWrapper className={sort}>
            <AccordionIllustration src={`/icons/desktop/${icon}.svg`} />
            <AccordionIllustrationMobile src={`/icons/mobile/${icon}.svg`} />
          </AccordionIllustrationWrapper>
          <AccordionMetaWrapper>
            <AccrodionTitle>{position}</AccrodionTitle>
            <AccordionMetaDetails>
              <AccordionMetaWage>
                <IconMeta src={moneyIcon} alt="coins" />
                <IconMetaMobile src={moneyIconMobile} alt="coins" />
                {wage}
              </AccordionMetaWage>
              <AccordionMetaLocation>
                <IconMeta src={locationIcon} alt="pin" />
                <IconMetaMobile src={locationIconMobile} alt="pin" />
                {location}
              </AccordionMetaLocation>
            </AccordionMetaDetails>
          </AccordionMetaWrapper>
        </AccordionHead>
        <Icon
          src={arrow}
          alt="arrow"
          style={{
            transform: open2 ? "rotate(180deg)" : "rotate(0deg)",
          }}
        ></Icon>
      </AccordionTitleSection>
      <AccordionDescriptionSection
        style={{ maxHeight: `${open ? height : "0px"}` }}
        ref={elRef}
      >
        <AccordionDescription>
          <AccordionDescriptionMeta>
            <SemiBold>
              <FormattedMessage id="offers.contract" />{" "}
            </SemiBold>
            {contract}
          </AccordionDescriptionMeta>
          <AccordionDescriptionMeta>
            <SemiBold>
              <FormattedMessage id="offers.work_period" />{" "}
            </SemiBold>
            {period}
          </AccordionDescriptionMeta>
          <AccordionDetails>{details}</AccordionDetails>
          <AccordionCTA to="/contact">
            <FormattedMessage id="offers.cta" />
          </AccordionCTA>
        </AccordionDescription>
      </AccordionDescriptionSection>
    </AccordionWrapper>
  )
}

export default Accordion
